.proj-page {
  font-family: Rajdhani;
  background-color: var(--background);
  padding-bottom: 1px;
}

.proj-page .page-title {
  background: var(--badge-blue);
  padding: 1em 0.5em;
  margin-bottom: 2em;
}
.proj-page .page-title h2 {
  font-size: 175%;
  text-shadow: 1px 1px 0 #fff;
}
.proj-page .card {
  font-weight: 500;
  margin-bottom: 3em;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.4);
  background: var(--foreground);
  border-right-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
}

.qr-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
  align-content: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 4px #555;
  display: none;
  font-weight: bold;
  font-size: 110%;
}

.qr-wrap-inner > * {
  display: block;
  margin: 0 auto 1em auto;
  text-shadow: 0 0 3px #000, 0 0 3px #000;
}

.show-qr .qr-wrap {
  display: flex;
}

.proj-page .card-header,
.proj-page .card .btn {
  background-color: var(--brand-blue);
  border: 1px solid transparent;
  font-weight: 500;
}
.proj-page .card .btn:hover {
  background-color: var(--brand-blue-shade);
}
.proj-page .card .btn[disabled]:hover {
  background-color: var(--brand-blue-shade);
  border-color: var(--brand-blue-shade);
}
.proj-page .card .btn[disabled] {
  border-color: var(--brand-blue);
  color: var(--brand-blue);
  background-color: var(--brand-blue-tint-lite);
  opacity: 0.6;
}

.proj-page .card-img {
  width: 100%;
  display: block;
  margin: 0 auto 2em auto;
  border: 1px solid var(--shade-dark);
  max-width: 440px;
}

.proj-stack {
  list-style: none;
  border: 1px solid #ccc;
  margin-top: 0;
  border-radius: 0.125em;
  padding: 0.5em 0.675em;
  display: inline-block;
  color: var(--brand-blue);
  line-height: 1.2;
  background: var(--badge-blue);
}
.proj-stack li {
  display: inline;
  padding: 0;
  font-weight: bold;
}
.proj-stack li + li:before {
  content: ", ";
}
.proj-page .card h3 {
  font-weight: bold;
  color: #fff;
  text-shadow: 1px -1px 0 var(--shade-dark);
  margin-bottom: 0;
}
.proj-page .card h5 {
  margin-bottom: 0.125em;
  margin-left: 0.5em;
  margin-top: 0.5em;
  text-transform: uppercase;
  color: var(--shade-dark);
}

.proj-desc {
  margin-left: 0.625em;
  margin-right: 0.625em;
}
