.home-page{
  font-family: Rajdhani;
  font-weight:500;
  font-size: 105%;
}

.paralax{
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  background-color: var(--brand-gray-lite);
  min-height: max(60vh, 300px);
  display: flex;
  flex-direction:column;
  justify-content: center;
}

.home-page h3{
  color:var(--brand-blue-shade);
  font-size: 200%;

}

.paralax-content{
  font-weight: 600;
  padding-top:3em;
  padding-bottom:3em;
  display: flex;
  align-items: center;
  justify-content: center;
 }

.paralax .hero-btn{
  padding: .25em 1em;
  margin:.5em ;
  border-radius: .5em;
  color:var(--btn-color);
  border:  2px solid var(--btn-bg);
  background: var(--btn-bg);
  text-shadow: none;
  font-weight: 600;
  box-shadow: 0 3px 7px #000;
  }

.inbetween{
  padding: 3em 2em;
  margin: 0;
}

.paralax .btn:active, .paralax .btn:hover{
  background: var(--btn-hover) !important;
  color: var(--btn-hov-color) !important;

  outline: none;
 }

.head-deck-wrap .fa, .head-deck-wrap .fas, .head-deck-wrap .fab, .head-deck-wrap .iconify{
  font-size: 2.3em;
  margin-bottom: .3em;
  color:  var(--shade);
}

.paralax .hero-sub{
  color:var(--hilite)
}

.paralax-content .hero-sub.sub-large{
  font-size:180%;
  line-height: 1.2;
 }
.paralax { font-size: 120%;
  --btn-bg:var(--hilite);
  --btn-color:var(--brand-blue-shade);
  --btn-hover: var(--shade-dark);
  --btn-hov-color:#fff;
  --hilite:rgb(255, 156, 0);

}
.paralax.tint-sec, .paralax.tint-m-sec,.paralax.shade-sec,  .paralax.shade-m-sec{
  position: relative;
}
.paralax, .paralax.tint-sec, .paralax.tint-m-sec{
  --text-shad:#fff;
  color:var(--brand-blue-shade);
 }

.paralax.shade-sec, .paralax.shade-m-sec{
  color:#fff;
  --text-shad:#000;
}

.paralax.tint-sec:before, .paralax.shade-sec:before,
.paralax.tint-m-sec:before, .paralax.shade-m-sec:before{
  content:'';
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
}

.paralax .hero-hed{
   font-weight: bold;
   font-size: 200%;
}

.paralax .hero-hed,.paralax .hero-sub, .paralax .hero-desc{
   text-shadow: 0 0 4px var(--text-shad);
}

.paralax.tint-sec:before {
  background-color:var(--tint-lite);
 }
.paralax.shade-sec:before {
  background-color:var(--shade-dark);
}
.paralax.tint-m-sec:before {
  background-color:var(--tint-med);
 }
.paralax.shade-m-sec:before {
  background-color:var(--shade-med);
}

.skill-grid .col-6 i{
  transition: transform .15s linear;
}
.skill-grid .col-6:hover i{
  transform-origin: center;
  transform: scale(1.3);
  color: var(--brand-blue);
}

.skill-grid .col-6 svg{
  transition: all .15s linear;
}
.skill-grid .col-6:hover svg{
  height: 1.3em;
  width: 1.3em;
  margin-bottom: .2em ;
  margin-top: -.2em ;
  color: var(--brand-blue);
}
.skill-grid .col-6:hover {
  color:var(--brand-blue);
  text-shadow:  1px 1px 0 #ccc;
}