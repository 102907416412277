.site-footer{
    font-size: 80%;
    background-color:  var(--brand-blue);
    border-top: 1px solid #ccc;
    font-family: Rajdhani;
}

.site-footer .row > div{ margin-bottom: .675em;}

.social-footer a{
    display:inline-block;
    font-size: 150%;
    margin: 0 .5em;
}

.social-footer {
    line-height: .01;
 }

.site-footer ,.site-footer a {
    color: #ccc;
}

.site-footer a:hover{
    text-decoration:none;
    color:#fff;
}