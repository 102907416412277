:root{
  scroll-behavior: smooth;
}

.resnav h2{
  font-size: 194.5%;
  text-shadow: 1px 1px 0 #fff;
}

.res-sec h4,
.res-sec h5,
.res-sec h6 {
  margin-bottom: 0;
}
.res-cont{
  padding-top: 1.325em;
}

.res-sec > h4 {
  letter-spacing: -1px;
  scroll-margin-top: 3.25em;
  -webkit-scroll-snap-margin-top: 3.25em;
}

.res-sec h4,
.res-sec h5,
.res-sec .employer {
  text-transform: uppercase;
}
.res-sec h4,
.res-sec .stack,
.res-sec .employer,
.res-sec .dates {
  font-weight: bold;
}

/*--type colors--*/
.res-sec h5,
.res-sec .edu-deg,
.res-sec .award-name,
.res-sec .award-list > li:before,
.res-sec .statement > strong,
.badges > span {
  color: var(--brand-blue);
}
.res-sec .dates {
  color:var(--shade-dark);
}
.res-sec .stack {
  color: var(--brand-blue-tint);
}
.res-sec .employer {
  color: var(--shade-med);
}

/*--type colors--*/

.res-sec, .res-sidenav{
  font-family: Rajdhani;
}
.res-sec .sec-hed {
  margin-top: 0.25em;
  margin-bottom: 0;
}

.project-list,
.badge-list,
.history-list,
.edu-list {
  margin: 0;
  margin-top: 0.5em;
  padding: 0;
  font-size: 90%;
  list-style: none;
}


.edu-list > li {
  margin-bottom: 0.5em;
}

.res-sec .statement {
  font-size: 105%;
  margin: 0;
}

.edu-list {
  padding-left: 1.5em;
}

.edu-list > li:before {
  content: "☆";
  font-size: 80%;
  float: left;
  margin-left: -1.5em;
}

.edu-list > li .job-des-list > li {
  margin-bottom: 0.375em;
}

.badge-list.row {
  margin-top: 0.25em !important;
}

.badge-list,
.employer .city {
  font-size: 80%;
}

/* using util class
.res-sec .employer {
  line-height: 1;
}
*/

.res-sec .employer .city {
  font-weight: 400;
  display: inline-block;
  text-transform: none;
}

.res-sec .stack {
  margin-top: -1em;
}

.badge-list {
  margin: 0 -0.5em;
  padding: 0;
}

li.badges.col-6 {
  padding: 0 0.5em;
}

.badges > span {
  font-weight: 700;
  border-radius: 3px;
  background: var(--badge-blue);
  padding: 0.125em 0.67em;
  margin: 0.5em 0;
  text-shadow: 0 1px var(--tint-lite);
  display: block;
}
.job-des-list {
  list-style-type: square;
  margin-left: -1.5em;
}

.res-sec {
  margin-bottom: 0.25em;
  padding-bottom: 1em;
 }
.res-sec + .res-sec {
  border-top: 1px solid #ccc;
}

.history-item  {
   padding-bottom:.67em;
}


/*Resume navigation*/
.res-sidenav {
  background: var(--badge-blue);
  border-left: 1px solid  var(--badge-blue);
}
.resnav{
  font-size: 90%;
  top: 6.625em;
  }

.navList{
  border-radius: 0 4px  4px 0    ;
  border:1px solid #ccc;
  border-left-color: #fff;
  overflow:hidden;
  margin: 1.625em 0 0 -17px ;
 }

.navList a {
  color:var(--brand-blue);
  background-color:#fff;
  padding-top:.375em;
  padding-bottom:.375em;
}

.navList a:hover{
  color:#fff;
  background:  var(--brand-blue);
  font-weight: bold;
}

.navList li + li{
	border-top:1px solid #ccc;
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .res-sec .employer, .res-sec .dates {
    line-height: 1;
  }
}